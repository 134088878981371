import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import Footer from "../components/Footer/Footer";
import logo from "../assets/logo.png";

function ContactPage() {
  return (
    <div>
      <Navbar />
      <div style={{ paddingTop: "100px" }}>
        <div className="container-xxl py-1" style={{ marginBottom: "30px" }}>
          <div className="container text-black py-5 px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <p style={{ fontSize: "25px" }}>
                  <img src={logo} alt="logo" />
                  <br />
                  <br />
                  <FaMapMarkerAlt size="30" />
                  Atasehir/İstanbul
                  <br />
                  Barbaros Mahallesi, Kardelen Sok. Palladium Tower No:2, Kat:10
                </p>
                <p style={{ fontSize: "25px" }}>
                  <BsFillTelephoneFill size="30" />0 216 687 09 79
                </p>
                <p style={{ fontSize: "25px" }}>
                  <MdEmail size="40" />
                  info@nwf.com.tr
                </p>
              </div>
              <div className="col-lg-6 ">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48187.61204227763!2d29.079001524073796!3d40.98748571620133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac62fc195dcf5%3A0x963499b30bcfd0c6!2sPalladium%20Tower!5e0!3m2!1str!2str!4v1699426949865!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: "0px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
