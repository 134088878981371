import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import a10Logo from "../assets/a10Logo.png";
import ciscoLogo from "../assets/ciscoLogo.png";
import juniperLogo from "../assets/juniperLogo.png";
import paloaltoLogo from "../assets/paloaltoLogo.png";
import sandvineLogo from "../assets/sandvineLogo.png";
import networkDesign from "../assets/networkDesign.png";
import supportAndMaintenance from "../assets/supportAndMaintenance.png";
import coding from "../assets/coding.png";
import Navbar from "../components/Navbar/Navbar.js";
import { Carousel } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import OwlCarousel from "react-owl-carousel";
import service1 from "../assets/service1.png";
import service2 from "../assets/service2.png";
const options = {
  autoplay: true,
  smartSpeed: 1000,
  margin: 25,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 2,
    },
    992: {
      items: 5,
    },
  },
};

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-5 py-3">
        <div className="container-xxl py-1 mt-3">
          <div className="container text-black py-5 px-lg">
            <div className="row  align-items-center">
              <Carousel indicators={false}>
                <Carousel.Item>
                  <img
                    className="w-100"
                    src={networkDesign}
                    alt="pictures1"
                    style={{
                      height: "600px",
                      maxHeight: "60vh",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={supportAndMaintenance}
                    alt="pictures2"
                    style={{
                      height: "600px",
                      maxHeight: "60vh",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={coding}
                    alt="pictures3"
                    style={{
                      height: "600px",
                      maxHeight: "60vh",
                    }}
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="container-xxl hero-header mt-5 ">
          <div className="container px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6  text-center">
                <img className="img-fluid " src={service1} alt="service1" />
              </div>
              <div className="col-lg-6 text-center" data-wow-delay="0.1s">
                <p className="mb-4" style={{ fontSize: "22px" }}>
                  Kurulum ve Danışmanlık hizmeti kapsamında network ve güvenlik
                  tasarımı, Demo, POC, Sistemlerin canlıya alımlarında destek,
                  eski yapıdan yeniye geçiş gibi konularda yardımcı
                  olabiliyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container-xxl hero-header mt-5 ">
          <div className="container px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 text-center" data-wow-delay="0.1s">
                <p className="mb-4" style={{ fontSize: "22px" }}>
                  Bakım Hizmetleri kapsamında 7/24 uzaktan ya da müşteri
                  lokasyonunda destek hedeflenen SLA lere uygun bir şekilde
                  sağlanmaktadır.
                </p>
              </div>
              <div className="col-lg-6  text-center">
                <img className="img-fluid " src={service2} alt="service2" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-xxl mt-5  " data-wow-delay="0.1s">
          <div className="container px-lg-3">
            <div className="row justify-content-center">
              <h1
                className="text-center"
                style={{ color: "#19164c", fontWeight: "bold" }}
              >
                İş Ortaklarımız
              </h1>
              <OwlCarousel
                items={5}
                className="owl-carousel testimonial-carousel"
                {...options}
                loop
                autoplay={true}
              >
                <img className="img" src={a10Logo} alt="pictures" />
                <img className="img" src={ciscoLogo} alt="pictures" />
                <img className="img" src={juniperLogo} alt="pictures" />
                <img className="img" src={paloaltoLogo} alt="pictures" />
                <img className="img" src={sandvineLogo} alt="pictures" />
              </OwlCarousel>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
