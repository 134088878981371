import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import EducationPage from "./pages/EducationPage";
import ServicesPage from "./pages/ServicesPage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route path="/hakkımızda" element={<AboutPage />}></Route>
        {/* <Route path='/egitimler' element={<EducationPage />}></Route> */}
        <Route path="/servisler" element={<ServicesPage />}></Route>
        <Route path="/iletisim" element={<ContactPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
