import React from "react";
import { useRef } from "react";

import "../Navbar/navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/logo.png";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <div className="container  p-3 mb-5 bg-white rounded fixed-top">
      <header>
        <a href="/">
          <img src={logo} alt="..." height="80" />
        </a>
        <nav ref={navRef}>
          <a href="/">
            <div style={{ fontSize: "25px" }}>
              <b style={{ color: "#19164c" }}>Anasayfa</b>
            </div>
          </a>
          {/* <a href="/egitimler"><div style={{ fontSize: "25px" }}><b style={{ color: "#19164c" }}>Eğitimler</b></div></a> */}
          <a href="/servisler">
            <div style={{ fontSize: "25px" }}>
              <b style={{ color: "#19164c" }}>Servisler</b>
            </div>
          </a>
          <a href="/hakkımızda">
            <div style={{ fontSize: "25px" }}>
              <b style={{ color: "#19164c" }}>Hakkımızda</b>
            </div>
          </a>
          <a href="/iletisim">
            <div style={{ fontSize: "25px" }}>
              <b style={{ color: "#19164c" }}>İletişim</b>
            </div>
          </a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
}

export default Navbar;
