import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import logo from "../assets/logo.png";
import service1 from "../assets/service1.png";
import service2 from "../assets/service2.png";

function ServicesPage() {
  return (
    <div>
      <Navbar />
      <div style={{ paddingTop: "100px" }}>
        <div className="container-xxl py-1" style={{ marginBottom: "30px" }}>
          <div className="container text-black py-5 px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-md-12 text-center mb-4">
                <img className="w-75" src={logo} alt="Logo" />
                <p style={{ fontSize: "22px" }}>
                  <br />
                  Network ve güvenlik alanında Türkiye genelinde yazılım ve
                  donanım satış, kurulum, danışmanlık ve bakım hizmetleri
                  sağlamaktayız.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl hero-header mt-5 ">
        <div className="container px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  text-center">
              <img className="img-fluid " src={service1} alt="service1" />
            </div>
            <div className="col-lg-6 text-center" data-wow-delay="0.1s">
              <p className="mb-4" style={{ fontSize: "22px" }}>
                Kurulum ve Danışmanlık hizmeti kapsamında network ve güvenlik
                tasarımı, Demo, POC, Sistemlerin canlıya alımlarında destek,
                eski yapıdan yeniye geçiş gibi konularda yardımcı olabiliyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container-xxl hero-header mt-5 ">
        <div className="container px-lg-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 text-center" data-wow-delay="0.1s">
              <p className="mb-4" style={{ fontSize: "22px" }}>
                Bakım Hizmetleri kapsamında 7/24 uzaktan ya da müşteri
                lokasyonunda destek hedeflenen SLA lere uygun bir şekilde
                sağlanmaktadır.
              </p>
            </div>
            <div className="col-lg-6  text-center">
              <img className="img-fluid " src={service2} alt="service2" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServicesPage;
