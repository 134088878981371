import React from "react";

function Footer() {
  return (
    <div style={{ paddingTop: "30px" }}>
      <div className="container">
        <footer className="py-3 my-4">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item">
              <a
                style={{ fontSize: "18px" }}
                href="/"
                className="nav-link px-2 text-muted"
              >
                Anasayfa
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                style={{ fontSize: "18px" }}
                href="/egitimler"
                className="nav-link px-2 text-muted"
              >
                Eğitimler
              </a>
            </li> */}
            <li className="nav-item">
              <a
                style={{ fontSize: "18px" }}
                href="/servisler"
                className="nav-link px-2 text-muted"
              >
                Servisler
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ fontSize: "18px" }}
                href="/hakkımızda"
                className="nav-link px-2 text-muted"
              >
                Hakkımızda
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ fontSize: "18px" }}
                href="/iletisim"
                className="nav-link px-2 text-muted"
              >
                İletişim
              </a>
            </li>
          </ul>
          <p className="text-center text-muted">© 2023 Company, Inc</p>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
