import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import logo from "../assets/logo.png";

function AboutPage() {
  return (
    <div>
      <Navbar />
      <div style={{ paddingTop: "100px" }}>
        <div className="container-xxl py-1" style={{ marginBottom: "30px" }}>
          <div className="container text-black py-5 px-lg-5">
            <div className="row g-5 align-items-center">
              <div className="col-md-12 text-center mb-4">
                <img className="w-75" src={logo} alt="Logo" />
                <p style={{ fontSize: "25px" }}>
                  <br />
                  <br />
                  Network Factory, sektörde tecrübeli bir ekip tarafından 2023
                  yılında kurulmuştur. Hem Servis Sağlayıcı hem Kurumsal
                  sektörde farklı şirketlerde network ve güvenlik deneyimleri
                  olan ve bunları müşterilerine yansıtmayı hedefleyen bir
                  kadroya sahiptir.
                  <br />
                  Şirketimizin ana amacı sahip olunan bu tecrübeyi paylaşmak ve
                  herkesin ulaşabileceği teknoloji hizmetleri vermektir.
                  Hizmetlerimizi titizlik ve güven esasına dayanarak
                  gerçekleştiriz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
